define("ember-svg-jar/inlined/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M86-154l394-652 394 652H86zm394.789-103q11.711 0 20.461-8.539 8.75-8.54 8.75-20.25 0-11.711-8.539-20.461-8.54-8.75-20.25-8.75-11.711 0-20.461 8.539-8.75 8.54-8.75 20.25 0 11.711 8.539 20.461 8.54 8.75 20.25 8.75zM458-360h46v-196h-46v196z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "20",
      "viewBox": "0 -960 960 960",
      "width": "20"
    }
  };
  _exports.default = _default;
});