define("ember-mapbox-gl/components/mapbox-gl-marker", ["exports", "ember-mapbox-gl/templates/components/mapbox-gl-marker"], function (_exports, _mapboxGlMarker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A utility that brokers HTTP requests...
   *
   * @class MapboxGlMarker
   */
  var _default = Ember.Component.extend({
    layout: _mapboxGlMarker.default,
    tagName: '',
    MapboxGl: null,
    map: null,
    initOptions: null,
    lngLat: null,
    init: function init() {
      var _Ember$getOwner$resol;

      this._super.apply(this, arguments);

      this.domContent = document.createElement('div');
      var lngLat = this.lngLat,
          initOptions = this.initOptions;
      (false && !(lngLat) && Ember.assert('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
      var options = Ember.assign({}, ((_Ember$getOwner$resol = Ember.getOwner(this).resolveRegistration('config:environment')['mapbox-gl']) !== null && _Ember$getOwner$resol !== void 0 ? _Ember$getOwner$resol : {}).marker, initOptions);
      this.marker = new this.MapboxGl.Marker(this.domContent, options).setLngLat(lngLat).addTo(this.map);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var lngLat = this.lngLat;
      (false && !(lngLat) && Ember.assert('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
      this.marker.setLngLat(lngLat);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.marker.remove();
    }
  });

  _exports.default = _default;
});