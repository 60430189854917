define("ember-cli-flash/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/fjXyO40",
    "block": "[[[11,0],[16,0,[29,[\"flash-message \",[30,0,[\"alertType\"]],\" \",[52,[30,0,[\"exiting\"]],\"exiting\"],\" \",[52,[30,0,[\"active\"]],\"active\"]]]],[24,\"role\",\"alert\"],[17,1],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[4,[38,2],[[30,0,[\"onDidInsert\"]]],null],[4,[38,3],[[30,0,[\"onWillDestroy\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[30,0],[33,6],[28,[37,7],[[30,0],\"onClose\"],null]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"flash\",\"message\"]]],[1,\"\\n\"],[41,[30,0,[\"showProgressBar\"]],[[[1,\"      \"],[10,0],[14,0,\"alert-progress\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"alert-progressBar\"],[15,5,[30,0,[\"progressDuration\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"did-insert\",\"will-destroy\",\"has-block\",\"yield\",\"flash\",\"action\"]]",
    "moduleName": "ember-cli-flash/templates/components/flash-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});