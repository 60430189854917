define("ember-mapbox-gl/components/mapbox-gl-source", ["exports", "ember-mapbox-gl/templates/components/mapbox-gl-source"], function (_exports, _mapboxGlSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Adds a data source to the map. The API matches the mapbox [source docs](https://www.mapbox.com/mapbox-gl-js/api/#sources).
  
    Example:
    ```hbs
    {{#mapbox-gl as |map|}}
      {{#map.source options=(hash
        type='geojson'
        data=(hash
          type='FeatureCollection'
          features=(array
            (hash
              type='Feature'
              geometry=(hash
                type='Point'
                coordinates=(array -96.7969879 32.7766642)
              )
            )
          )
        ))}}
      {{/map.source}}
    {{/mapbox-gl}}
    ```
  
    @class MapboxGLSource
  */
  var _default = Ember.Component.extend({
    layout: _mapboxGlSource.default,
    tagName: '',
    map: null,

    /**
      @argument options
      @type {Object}
      @description
      An options hash to set as the source.
      - #### `options.type`
        - A string detailing the map source type. Typically `geojson`.
       - #### `options.data`
        - A data hash for the map, following the source.data API detailed by mapbox docs.
    */
    options: null,

    /**
     * @property options
     * @type {Object}
     * @description The ID of the source to add. Must not conflict with existing sources.
     * {@link https://www.mapbox.com/mapbox-gl-js/api/#map#addsource Mapbox}
     */
    sourceId: Ember.computed({
      get: function get() {
        return Ember.guidFor(this);
      },
      set: function set(k, v) {
        return v;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.map.addSource(this.sourceId, this.options);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var sourceId = this.sourceId,
          options = this.options;

      if (options) {
        if (options.data) {
          this.map.getSource(sourceId).setData(options.data);
        } else if (options.coordinates) {
          this.map.getSource(sourceId).setCoordinates(options.coordinates);
        }
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var sourceId = this.sourceId; // wait for any layers to be removed before removing the source

      Ember.run.scheduleOnce('afterRender', this.map, this.map.removeSource, sourceId);
    }
  });

  _exports.default = _default;
});