define("ember-svg-jar/inlined/nav.alarms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"currentColor\"><path d=\"M18.641 16.6h-.733v-4.823a5.869 5.869 0 00-5.862-5.862 5.869 5.869 0 00-5.862 5.862v4.824H5.36a1.857 1.857 0 00-1.855 1.855v2.671a.37.37 0 00.37.371h16.251a.371.371 0 00.371-.37v-2.672a1.857 1.857 0 00-1.855-1.855zM6.926 11.778a5.126 5.126 0 015.12-5.12 5.126 5.126 0 015.12 5.12v4.824H6.926v-4.824zm12.828 8.979H4.246v-2.3c0-.614.499-1.113 1.113-1.113H18.64c.614 0 1.113.499 1.113 1.113v2.3zM12 5.025a.371.371 0 00.371-.371V2.873a.371.371 0 00-.742 0v1.78c0 .206.166.372.371.372zm9.127 6.381H19.17a.371.371 0 000 .742h1.957a.371.371 0 000-.742zM18.21 8.564a.37.37 0 00.185-.05l1.695-.979a.371.371 0 10-.371-.642l-1.695.978a.371.371 0 00.186.693zM15.4 5.89a.37.37 0 00.506-.136l.979-1.695a.371.371 0 10-.643-.371l-.978 1.695a.371.371 0 00.135.506zM5.201 11.777a.371.371 0 00-.37-.37H2.872a.371.371 0 000 .741H4.83a.371.371 0 00.371-.37zM3.91 7.535l1.695.979a.37.37 0 00.507-.136.371.371 0 00-.136-.507l-1.695-.978a.371.371 0 10-.37.642zm4.184-1.781a.37.37 0 10.642-.371l-.978-1.695a.371.371 0 00-.643.37l.979 1.696z\"/><path d=\"M10.675 16.995v-3.91c0-.808.658-1.466 1.466-1.466.808 0 1.466.658 1.466 1.466v3.91h-2.932zm3.42 0v-3.91a1.957 1.957 0 00-1.954-1.954 1.957 1.957 0 00-1.955 1.954v3.91h3.91z\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});