define("ember-svg-jar/inlined/nav.users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.942 12.025c-5.007 0-9.08 4.094-9.08 9.127 0 .219.177.397.394.397a.396.396 0 00.395-.397c0-4.596 3.72-8.334 8.29-8.334 4.572 0 8.291 3.738 8.291 8.334 0 .219.177.397.395.397a.396.396 0 00.395-.397c0-5.034-4.074-9.127-9.08-9.127zm0-9.573c-2.394 0-4.343 1.958-4.343 4.365s1.949 4.365 4.343 4.365 4.342-1.958 4.342-4.365-1.948-4.365-4.342-4.365zm0 7.936c-1.96 0-3.553-1.602-3.553-3.571 0-1.97 1.594-3.572 3.553-3.572 1.959 0 3.553 1.603 3.553 3.572s-1.594 3.571-3.553 3.571z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});