define("ember-svg-jar/inlined/nav.properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"currentColor\"><path d=\"M14.265 10.942L7.328 4.5.39 10.942a.242.242 0 00.329.354l.803-.745v8.066h11.61v-8.066l.803.745a.24.24 0 00.342-.013.242.242 0 00-.013-.341zm-1.616 7.191H2.007v-8.032l5.32-4.94 5.322 4.94v8.032z\"/><path d=\"M23.653 10.942L16.716 4.5l-2.662 2.472-2.237-2.102-2.336 1.915L7.328 4.87l-5.572 5.165v8.582h20.765v-8.066l.803.745a.24.24 0 00.342-.013.242.242 0 00-.013-.341zm-1.616 7.191H1.813v-8.032l5.515-5.215L9.487 6.87l2.198-2.129 2.661 2.619 2.37-2.2 5.321 4.94v8.032z\"/><path d=\"M18.622 10.942L11.685 4.5l-2.36 2.192 3.608 3.449v8.162l-7.053.314h11.61v-8.066l.802.745a.24.24 0 00.342-.013.242.242 0 00-.012-.341zm-1.616 7.191H6.363h6.56v-8.032l-3.254-3.07 2.016-1.87 5.32 4.94v8.032z\"/><path d=\"M14.166 10.896L7.278 4.5.39 10.896a.24.24 0 00.327.352l.797-.74v8.01h11.528v-8.01l.797.74a.239.239 0 00.34-.012.24.24 0 00-.013-.34zm-8.329 7.141v-3.843a1.442 1.442 0 012.882 0v3.843H5.837zm6.725 0H9.199v-3.843a1.923 1.923 0 00-1.921-1.921c-1.06 0-1.921.862-1.921 1.921v3.843H1.994v-7.975l5.284-4.907 5.284 4.907v7.975z\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});