define("ember-mirage-sauce/mirage-serializers/json-api-serializer", ["exports", "ember-cli-mirage", "ember-inflector", "ember-mirage-sauce/utils/find-nested-relationship", "ember-get-config"], function (_exports, _emberCliMirage, _emberInflector, _findNestedRelationship, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var DEBUG = _emberGetConfig.default["mirage-sauce"] ? _emberGetConfig.default["mirage-sauce"].debug : false;
  var DEFAULT_SEARCH_FIELDS = ["email", "name", "firstName", "lastName"];
  /**
    A custom JSONAPISerializer that adds sorting, filtering, search &
    pagination to api requests
  
    ```js
    // in mirage/serializers/application.js
    import JSONAPISerializer from 'ember-mirage-sauce/mirage-serializers/json-api-serializer';
  
    export default JSONAPISerializer.extend({});
    ```
  
    @class JSONAPISerializer
  */

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    /**
      Define an array of fields in the model to fuzzy search
       @property searchByFields
      @type {Array}
     */
    searchByFields: DEFAULT_SEARCH_FIELDS,

    /**
      Query param name for the search parameter
      _Default: "search"
       @property searchKey
      @default "search"
      @type {string}
     */
    searchKey: "search",

    /**
      Query param name for the sort parameter
      _Default: "sort"
       @property sortKey
      @default "sort"
      @type {string}
     */
    sortKey: "sort",

    /**
      Query param name for the filter parameters
      _Default: "filter"
       @property filterKey
      @default "filter"
      @type {string}
     */
    filterKey: "filter",

    /**
      Define any filter keys to ignore in mirage
      queries
      _Default: []
       @property ignoreFilters
      @default "[]"
      @type {array}
     */
    ignoreFilters: Ember.A([]),

    /**
      Define a hook to do your own filtering on a
      request before it is paginated. This closure
      function is passed the json and request objects
      and should return the json object
      _Default: null
       @property filterHook
      @default null
      @type {function}
     */
    filterHook: null,

    /**
      Override the parent serializer to add support for search,
      filter, sort & pagination
       @method serialize
      @access public
      @param {Object} object
      @param {Object} request
      @return {Hash}
     */
    serialize: function serialize(object, request) {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _emberCliMirage.JSONAPISerializer.prototype.serialize.apply(this, arguments); // Add metadata, sort parts of the response, etc.
      // Is this a list response


      if (Array.isArray(json.data)) {
        return this._serialize(json, request);
      } //


      return json;
    },
    _serialize: function _serialize(json, request) {
      this.log("====================");
      this.log("MIRAGE SAUCE REQUEST");
      this.log("===================="); // Get filter params from request

      this.log("> Payload data", json);
      this.log("> Request object", request);

      var filters = this._extractFilterParams(request.queryParams); // Filter data


      json.data = this.filterResponse(json, filters); // Sort data

      json.data = this.sortResponse(json, Ember.get(request.queryParams, this.sortKey)); // Any Hooks?

      var hook = this.filterHook;

      if (hook) {
        json = hook(json, request);
        this.log("3.  Filter hook called");
      } else {
        this.log("3.  Filter hook not set");
      } // Paginate?


      json = this.paginate(json, request);
      return json;
    },

    /**
      Filter responses by filter params
       _NOTE! to filter by a relationship id it must be included
      in the requests "include" param. Otherwise this serializer
      does not include data from that relationship in it's 'data'_
       @access protected
      @method filterResponse
      @param {Array} data
      @param {Array} filters
      @return {Array}
     */
    filterResponse: function filterResponse(json, filters) {
      var _this = this;

      var data = json.data;

      if (filters.length) {
        this.log("1.  Filter the response: filters", filters);
        filters.forEach(function (filter, index) {
          _this.log("1.".concat(index, ".0 filter"), filter);

          if (_this.ignoreFilters.indexOf(filter.property) !== -1) {
            _this.log("1.".concat(index, ".1 ignoring ").concat(filter.property, " filter"));

            return;
          }

          var attributePath = "attributes.".concat(Ember.String.dasherize(filter.property));
          var logFirst = true;
          data = data.filter(function (record) {
            var match = false;
            filter.property = Ember.String.dasherize(filter.property);
            filter.values.forEach(function (value) {
              if (logFirst) {
                _this.log("1.".concat(index, ".1 Filter ").concat(filter.property, "=\"").concat(value, "\""));
              } // Check for an attribute match
              // Is this a search term?


              if (filter.property === _this.searchKey && value) {
                if (logFirst) {
                  _this.log("1.".concat(index, ".2 Filter by search key: ").concat(filter.property, "=\"").concat(value, "\""));

                  _this.log("1.".concat(index, ".3 Search the followin attributes: ").concat(_this.searchByFields.join(", ")));
                }

                if (_this.filterBySearch(record, value)) {
                  match = true;
                }
              } // Is this an attribute filter?
              else if (_this._isAttributeKey(filter.property, record)) {
                  var attribute = Ember.get(record, attributePath); // Convert bool to string

                  if (typeof attribute === "boolean") {
                    attribute = attribute.toString();
                  } // Convert number to string


                  if (typeof attribute === "number") {
                    attribute = attribute.toString();
                  }

                  if (logFirst) {
                    _this.log("1.".concat(index, ".2 Filter by attribute ").concat(filter.property));
                  }

                  if (value === attribute) {
                    match = true;
                  }
                } // Is this a related belongs to id?
                else if (filter.property.endsWith("-id")) {
                    var relationship = filter.property.replace("-id", ""),
                        path = "relationships.".concat(relationship, ".data.id");

                    if (logFirst) {
                      _this.log("1.".concat(index, ".2 Filter by \"").concat(filter.property, "\" is a belongsTo relationship. Path: ").concat(path));
                    } // check the related model is present in the response


                    if (_this._hasIncludedRelationship(relationship, json.included)) {
                      // Check for a relationship match
                      if (parseInt(value) === parseInt(Ember.get(record, path))) {
                        match = true;
                      }
                    } else {
                      if (logFirst) {
                        _this.log("1.".concat(index, ".3 There were no \"").concat(relationship, "\" models found in the includes response! Did you include them in your request?"));
                      }
                    }
                  } // Is this a related hasMany to id(s)?
                  else if (filter.property.endsWith("-ids")) {
                      // Has Many Relationship
                      var _relationship = filter.property.replace("-ids", ""),
                          _path = "relationships.".concat((0, _emberInflector.pluralize)(_relationship), ".data");

                      if (logFirst) {
                        _this.log("1.".concat(index, ".2 Filter by \"").concat(filter.property, "\" is a hasMany relationship. Path: ").concat(_path));
                      } // check the related model is present in the response


                      if (_this._hasIncludedRelationship(_relationship, json.included)) {
                        // Loop though relationships for a match
                        Ember.get(record, _path).forEach(function (related) {
                          if (parseInt(value) === parseInt(related.id)) {
                            match = true;
                          }
                        });
                      } else {
                        if (logFirst) {
                          _this.log("1.".concat(index, ".3 There were no \"").concat(_relationship, "\" models found in the includes response! Did you include them in your request?"));
                        }
                      }
                    } // Is this a related attribute?
                    else if (filter.property.includes(".")) {
                        var segments = filter.property.split("."),
                            // last item will be the property
                        relationshipProperty = segments[segments.length - 1]; // check this path exists in the includes property of our response data

                        if (relationshipProperty !== "id") {
                          relationshipProperty = "attributes.".concat(relationshipProperty);
                        } // find the nested relationship from the included array


                        var _relationship2 = (0, _findNestedRelationship.default)(record, json.included, filter.property);

                        if (logFirst) {
                          _this.log("1.".concat(index, ".2 Filter by \"").concat(filter.property, "\" is a relationship attribute. Path: \"").concat(relationshipProperty, "\""));
                        }

                        if (_relationship2) {
                          if (Ember.get(_relationship2, relationshipProperty) == value) {
                            match = true;
                          }
                        }
                      } else {
                        if (logFirst) {
                          _this.log("1.".concat(index, ".2 Filter did not know how to handle \"").concat(filter.property, "\" ").concat(record.id, " so it was ignored"));
                        }

                        match = true;
                      }
            });
            logFirst = false;
            return match;
          });
        });
      } else {
        this.log("1.  Filter the response: No filters set");
      }

      return data;
    },

    /**
      Check if the model passes search filter
       @access protected
      @method filterBySearch
      @param {object}    record Serialised model instance to search
      @param {string}    term The search term
      @return {boolean}
     */
    filterBySearch: function filterBySearch(record, term) {
      var searchFields = this.searchByFields;

      if (Ember.isEmpty(searchFields)) {
        // no search fields - return record
        return true;
      }

      var matched = false;
      searchFields.forEach(function (field) {
        var fieldValue = Ember.get(record, "attributes.".concat(Ember.String.dasherize(field)));

        if (!Ember.isEmpty(fieldValue) && fieldValue.toLowerCase().search(term.toLowerCase()) !== -1) {
          matched = true;
        }
      });
      return matched;
    },

    /**
      Order responses by sort param
       _Supports one sort param atm..._
      http://jsonapi.org/format/#fetching-sorting
       @access protected
      @method sortResponse
      @param {Array} data
      @param {Array} filters
      @return {Array}
     */
    sortResponse: function sortResponse(json, sort) {
      var desc = false,
          data = json.data;

      if (sort && data.length > 0) {
        this.log("2.  Sort the response", sort); // does this sort param start with "-"

        if (sort.indexOf("-") === 0) {
          // sort decending
          desc = true; // remove prefixed '-'

          sort = sort.substring(1);
          this.log("2.0 Sort direction: descending");
        } else {
          this.log("2.0 Sort direction: ascending");
        } // find the sort path


        if (this._isAttribute(sort)) {
          var path = this._getAttributePath(sort, data[0]);

          this.log("2.1 Sort by attribute \"".concat(sort, "\". Path:"));
          this.log("2.2 Sort by path \"".concat(path, "\"")); // sort by property

          data = Ember.A(data).sortBy(path);
        } else if (this._isRelatedAttribute(sort)) {
          this.log("2.1 Sort by related attribute \"".concat(sort, "\".")); // sort by related

          data = this._sortByIncludedProperty(data, json.included, sort);
        } // reverse sort order?


        if (desc) {
          data = Ember.A(data).reverseObjects();
        }
      } else {
        this.log("2.  Sort the response: No sort defined");
      }

      return data;
    },

    /**
      Paginate response
       @access protected
      @method paginate
      @param {object} results data to be paginated
      @param {object} request request object
      @return {object}
     */
    paginate: function paginate(res, request) {
      if (request.queryParams["page[number]"] && request.queryParams["page[size]"]) {
        var page = parseInt(request.queryParams["page[number]"]),
            size = parseInt(request.queryParams["page[size]"]),
            total = res ? res.data.length : 0,
            pages = Math.ceil(total / size);
        this.log("4.  Pagination the response page \"".concat(page, "\" size \"").concat(size, "\""));
        res.data = this._sliceResults(res.data, page, size);
        res.meta = this._buildMetadata(page, size, total, pages);
        return res;
      } else {
        this.log("4.  Pagination not set");
        return res;
      }
    },
    // -------
    // PRIVATE
    // -------
    _sliceResults: function _sliceResults(results, page, size) {
      var start = (page - 1) * size;
      var end = start + size;
      this.log("3.0 total results: ".concat(results.length));
      this.log("3.1 slice results at index: ".concat(start, " - ").concat(end));
      return results.slice(start, end);
    },
    _buildMetadata: function _buildMetadata(page, size, total, pages) {
      this.log("3.2 total pages: ".concat(pages));
      return {
        page: page,
        size: size,
        total: total,
        pages: pages
      };
    },

    /**
      Extract filter parameters from the request
       @access private
      @param {Object} params
      @return {Array}
     */
    _extractFilterParams: function _extractFilterParams(params) {
      var filters = Ember.A([]);

      for (var key in params) {
        // loop though params and match any that follow the
        // filter[foo] pattern. Then extract foo.
        if (key.substr(0, 6) === this.filterKey) {
          var property = key.substr(7, key.length - 8),
              value = params[key],
              values = null;

          if (value) {
            // make sure it's a string before we split it
            values = (value + "").split(",");
          }

          if (!Ember.isEmpty(values)) {
            filters.pushObject({
              property: property,
              values: values
            });
          }
        }
      }

      return filters;
    },

    /**
      Sort models by a related property
       @access private
      @param {Array} data       Array of serialized models to sort
      @param {Array} included   Collection of included serialized models
      @param {string} sort      Sort property
      @return {Array}
     */
    _sortByIncludedProperty: function _sortByIncludedProperty(data, included, sort) {
      var _this2 = this;

      var idPath = this._getRelatedIdPath(sort, data[0]),
          model = this._getRelatedModel(sort),
          attrPath = this._getRelatedAttributePath(sort, data[0]);

      this.log("2.2 Sort by path of included model \"".concat(model, "\" \"").concat(attrPath, "\""));
      var logFirst = true;
      return data.sort(function (a, b) {
        var aId = Ember.get(a, idPath),
            bId = Ember.get(b, idPath),
            aRelated = _this2._findIncludedModelById(included, model, aId),
            bRelated = _this2._findIncludedModelById(included, model, bId); // Bale if we didnt find a related model


        if (!aRelated || !bRelated) {
          _this2.log("2.3 Couldnt find related model ".concat(model, " in response"));

          return 0;
        }

        var aVal = Ember.get(aRelated, attrPath),
            bVal = Ember.get(bRelated, attrPath); // are they numbers?

        if (!isNaN(parseFloat(aVal)) && !isNaN(parseFloat(bVal))) {
          if (logFirst) {
            _this2.log("2.3 Sort by values as numbers");

            logFirst = false;
          }

          aVal = parseFloat(aVal);
          bVal = parseFloat(bVal);
        } else {
          if (logFirst) {
            _this2.log("2.3 Sort by by values as strings");

            logFirst = false;
          }
        }

        if (aVal > bVal) {
          return 1;
        } else if (aVal < bVal) {
          return -1;
        } else {
          return 0;
        }
      }); // return data;
    },
    _isAttribute: function _isAttribute(path) {
      return path.split(".").length === 1;
    },
    _isAttributeKey: function _isAttributeKey(attribute, record) {
      return Object.keys(record.attributes).includes(attribute);
    },
    _hasIncludedRelationship: function _hasIncludedRelationship(model, included) {
      return Ember.A(included).filterBy("type", (0, _emberInflector.pluralize)(model)).length > 0;
    },
    _isRelatedAttribute: function _isRelatedAttribute(path) {
      return path.split(".").length === 2;
    },
    _getRelatedIdPath: function _getRelatedIdPath(property) {
      // ensure param is underscored
      property = Ember.String.dasherize(property); // destructure property

      var relatedModel = property.split(".")[0]; // define full path

      var path = "relationships.".concat(relatedModel, ".data.id");
      return path;
    },
    _getAttributePath: function _getAttributePath(property, record) {
      // ensure param is underscored
      property = Ember.String.dasherize(property); // define full path

      var path = "attributes.".concat(property); // check if path is found

      if (typeof Ember.get(record, path) === "undefined") {
        this.log("Mirage: Could not find path ".concat(path));
        this.log(record);
      }

      return path;
    },
    _getRelatedModel: function _getRelatedModel(property) {
      // ensure param is underscored
      property = Ember.String.dasherize(property); // destructure property

      property = property.split(".")[0];
      return property;
    },
    _getRelatedAttributePath: function _getRelatedAttributePath(property) {
      // ensure param is underscored
      property = Ember.String.dasherize(property); // destructure property

      property = property.split(".")[1]; // define full path

      var path = "attributes.".concat(property);
      return path;
    },
    _findIncludedModelById: function _findIncludedModelById(array, model, id) {
      return array.find(function (item) {
        return item.type === (0, _emberInflector.pluralize)(model) && item.id === id;
      });
    },
    _findRecordPath: function _findRecordPath(property, record) {
      var path; // ensure param is underscored

      property = Ember.String.dasherize(property); // destructure property

      var _property$split = property.split("."),
          _property$split2 = _slicedToArray(_property$split, 2),
          a = _property$split2[0],
          b = _property$split2[1]; // work out if this is a related property or not
      // and return the key


      if (!Ember.isEmpty(b)) {
        path = "relationships.".concat(a, ".data.").concat(b);
      } else {
        path = "attributes.".concat(a);
      } // check if path is found


      if (typeof Ember.get(record, path) === "undefined") {
        this.log("Mirage: Could not find path ".concat(path));
        this.log(record);
      } // warn user else


      return path;
    },
    log: function log() {
      if (DEBUG) {
        var _window$console;

        (_window$console = window.console).log.apply(_window$console, arguments);
      }
    }
  });

  _exports.default = _default;
});