define("ember-svg-jar/inlined/fr-ca", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#f0f0f0\" d=\"M0 85.331h512v341.326H0z\"/><path d=\"M0 85.331h170.663v341.337H0zm341.337 0H512v341.337H341.337zM288 279.704l32-16-16-7.999v-16l-32 16 16-32h-16l-16-24.001-16 24.001h-16l16 32-32-16v16l-16 7.999 32 16-7.999 16h32v24.001h16v-24.001h32z\" fill=\"#d80027\"/>",
    "attrs": {
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});