define("ember-mapbox-gl/templates/components/mapbox-gl-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4qUlcNBf",
    "block": "[[[18,1,[[28,[37,1],null,[[\"id\"],[[30,0,[\"_layerId\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl-layer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});