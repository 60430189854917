define("ember-cognito/utils/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeAttributes = normalizeAttributes;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * This takes a hash of attributes or a list of CognitoUserAttributes list,
   * and returns a hash. It also deprecates the CognitoUserAttributes path.
   *
   * @param attributes
   */
  function normalizeAttributes(attributes) {
    var showDeprecation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    // If the attributeList is an object, then it is treated as
    // a hash of attributes, otherwise it is treated as a list of CognitoUserAttributes,
    // for backward compatibility.
    if (Ember.typeOf(attributes) === 'array') {
      (false && !(!showDeprecation) && Ember.deprecate('You can pass a hash to this function rather than a list of CognitoUserAttribute objects.', !showDeprecation, {
        id: 'ember-cognito-attribute-list',
        until: '1.0'
      }));
      var newAttrs = {};

      var _iterator = _createForOfIteratorHelper(attributes),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var attr = _step.value;
          newAttrs[attr.getName()] = attr.getValue();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      attributes = newAttrs;
    }

    return attributes;
  }
});