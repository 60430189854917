define("ember-mapbox-gl/components/mapbox-gl-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * A component used to call a specific Mapbox GL method.
   *
   * @class MapboxGLCallComponent
   */
  var MapboxGlCallComponent = Ember.Component.extend({
    tagName: '',
    obj: null,
    func: null,
    args: null,
    onResp: function onResp() {},
    params: null,
    _scheduledCall: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var obj = this.obj,
          func = this.func,
          args = this.args,
          params = this.params;

      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          var _params = _toArray(params);

          func = _params[0];
          args = _params.slice(1);
        }
      }

      (false && !(_typeof(obj) === 'object' && obj !== null) && Ember.assert('mapbox-gl-call obj is required', _typeof(obj) === 'object' && obj !== null));
      (false && !(typeof func === 'string') && Ember.assert('mapbox-gl-call func is required and must be a string', typeof func === 'string'));
      (false && !(typeof obj[func] === 'function') && Ember.assert("mapbox-gl-call ".concat(func, " must be a function on ").concat(obj), typeof obj[func] === 'function'));
      this._scheduledCall = Ember.run.scheduleOnce('afterRender', this, this._call, obj, func, args);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this._scheduledCall !== null) {
        Ember.run.cancel(this._scheduledCall);
      }
    },
    _call: function _call(obj, func, args) {
      this._scheduledCall = null;
      this.onResp(obj[func].apply(obj, args));
    }
  });
  MapboxGlCallComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = MapboxGlCallComponent;
  _exports.default = _default;
});