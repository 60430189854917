define("ember-changeset-validations/validators/date", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults", "ember-changeset-validations/utils/to-date"], function (_exports, _validationErrors, _withDefaults, _toDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDate;
  _exports.errorFormat = void 0;
  var errorFormat = "MMM Do, YYYY";
  _exports.errorFormat = errorFormat;

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function validateDate() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    options = (0, _withDefaults.default)(options, {
      allowBlank: false,
      errorFormat: errorFormat
    });
    return function (key, value) {
      var _options = options,
          allowBlank = _options.allowBlank;
      var _options2 = options,
          before = _options2.before,
          onOrBefore = _options2.onOrBefore,
          after = _options2.after,
          onOrAfter = _options2.onOrAfter,
          message = _options2.message;
      var type = 'date';

      if (allowBlank && (typeof value === 'undefined' || value === null)) {
        return true;
      }

      var date = (0, _toDate.default)(value);

      if (!isValidDate(date)) {
        return (0, _validationErrors.default)(key, {
          type: type,
          value: 'not a date',
          context: {
            value: value,
            message: message
          }
        });
      }

      if (before) {
        before = (0, _toDate.default)(before);
        message = message || "[BEFORE] date is NOT before ".concat(value);
        type = 'before';

        if (date >= before) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              before: before,
              message: message
            }
          });
        }
      }

      if (onOrBefore) {
        onOrBefore = (0, _toDate.default)(onOrBefore);
        message = message || "[ON OR BEFORE] date is NOT on or before ".concat(value);
        type = 'onOrBefore';

        if (date > onOrBefore) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              onOrBefore: onOrBefore,
              message: message
            }
          });
        }
      }

      if (after) {
        after = (0, _toDate.default)(after);
        message = message || "[AFTER] date is NOT after ".concat(value);
        type = 'after';

        if (date <= after) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              after: after,
              message: message
            }
          });
        }
      }

      if (onOrAfter) {
        onOrAfter = (0, _toDate.default)(onOrAfter);
        message = message || "[ON OR AFTER] date is NOT on or after ".concat(value);
        type = 'onOrAfter';

        if (date < onOrAfter) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              onOrAfter: onOrAfter,
              message: message
            }
          });
        }
      }

      return true;
    };
  }
});