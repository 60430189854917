define("ember-mirage-sauce/utils/find-nested-relationship", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = findNestedRelationship;

  /**
   * findNestedRelationship
   *
   * @param {<object>}	record - main resource object
   * @param {<array>}	relationships - array of all included relationships
   * @param {<string>}	path - the string path for the nested relationship
   *
   * @return {<object>}
   */
  function findNestedRelationship(record, relationships, path) {
    var pathSegments = path.split('.'),
        // property = pathSegments.pop(),
    firstRelationship = pathSegments.shift(),
        // first relationship will be in the data object
    firstRelationshipId = parseInt(Ember.get(record, "relationships.".concat(firstRelationship, ".data.id"))); // access first relationships object from the includes array

    firstRelationship = relationships.find(function (relationship) {
      return parseInt(relationship.id) === firstRelationshipId && relationship.type === (0, _emberInflector.pluralize)(firstRelationship);
    });

    if (!firstRelationship) {
      return null;
    }

    if (pathSegments.length === 1) {
      return firstRelationship;
    }

    var currentRelationship = firstRelationship,
        lastRelationship;

    var _loop = function _loop() {
      var nextRelationshipModel = pathSegments.shift(),
          nestedPath = "relationships.".concat(nextRelationshipModel, ".data.id"),
          nextRelationshipId = Ember.get(currentRelationship, nestedPath);

      if (!nextRelationshipId) {
        return "break";
      }

      currentRelationship = relationships.find(function (relationship) {
        return parseInt(relationship.id) === nextRelationshipId && relationship.type === (0, _emberInflector.pluralize)(nextRelationshipModel);
      });
      lastRelationship = currentRelationship;
    };

    while (pathSegments.length > 0) {
      var _ret = _loop();

      if (_ret === "break") break;
    }

    if (lastRelationship) {
      return lastRelationship;
    } else {
      return null;
    }
  }
});