define("ember-mapbox-gl/components/mapbox-gl", ["exports", "ember-mapbox-gl/templates/components/mapbox-gl", "ember-mapbox-gl/-private/mapbox-loader"], function (_exports, _mapboxGl, _mapboxLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Component that creates a new [mapbox-gl-js instance](https://www.mapbox.com/mapbox-gl-js/api/#map):
  
    ```hbs
    {{#mapbox-gl initOptions=initOptions mapLoaded=(action 'mapLoaded') as |map|}}
  
    {{/mapbox-gl}}
    ```
  
    @class MapboxGL
    @yield {Hash} map
    @yield {Component} map.call
    @yield {Component} map.control
    @yield {Component} map.image
    @yield {Component} map.layer
    @yield {Component} map.marker
    @yield {Component} map.on
    @yield {Component} map.popup
    @yield {Component} map.source
  */
  var _default = Ember.Component.extend({
    layout: _mapboxGl.default,

    /**
      An options hash to pass on to the [mapbox-gl-js instance](https://www.mapbox.com/mapbox-gl-js/api/).
      This is only used during map construction, and updates will have no effect.
       @argument initOptions
      @type {Object}
    */
    initOptions: null,

    /**
      An action function to call when the map has finished loading. Note that the component does not yield until the map has loaded,
      so this is the only way to listen for the mapbox load event.
       @argument mapLoaded
      @type {Function}
    */
    mapLoaded: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._loader = _mapboxLoader.default.create();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _ref = Ember.getOwner(this).resolveRegistration('config:environment')['mapbox-gl'] || {},
          accessToken = _ref.accessToken,
          map = _ref.map;

      var options = Ember.assign({}, map, this.initOptions);
      options.container = this.element;

      this._loader.load(accessToken, options, this.mapLoaded);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._loader.cancel();
    }
  });

  _exports.default = _default;
});