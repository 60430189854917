define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-uk", {
    "device": {
      "gas-consumption": {
        "boilerModel": {
          "label": "Boiler Model",
          "tip": "The model of the boiler"
        },
        "dayPowerUseage": {
          "label": "24 Hour Total (KWH)",
          "tip": "The amount of power used in 24 hours"
        },
        "detail": {
          "body": "<p>To see how we calculate the total gas consumption used for devices, and validate the estimated values produced by the equation email us at enquires@idealheating.com</p>",
          "title": "Details"
        },
        "detail-summary": {
          "title": "Summary"
        },
        "header": "Estimated Gas Consumption Calculation",
        "maxChFanSpeed": {
          "label": "Max CH Fan Speed",
          "tip": "The maximum central heating fan speed"
        },
        "maxDhwFanSpeed": {
          "label": "Max DHW Fan Speed",
          "tip": "The maximum domestic hot water fan speed"
        },
        "minFanSpeed": {
          "label": "Min Fan Speed",
          "tip": "The minimum fan speed"
        },
        "monthPowerUseage": {
          "label": "Month Total (KWH)",
          "tip": "The amount of power used in a month"
        },
        "table": {
          "header": {
            "date": "Date",
            "fanAggregate": "Fan Aggregate (Ch)",
            "flameOnDuration": "Flame on Duration (Ch)",
            "gasConsumption": "Calculated Gas Consumption (kWh)",
            "time": "Time"
          }
        },
        "weekPowerUseage": {
          "label": "Week Total (KWH)",
          "tip": "The amount of power used in a week"
        },
        "yearPowerUseage": {
          "label": "Year Total (KWH)",
          "tip": "The amount of power used in a year"
        }
      }
    },
    "footer": {
      "version": "Version"
    },
    "home": {
      "title": "Welcome to the Triangle Tube portal"
    },
    "languages": {
      "en-uk": "English (UK)",
      "en-us": "American English (US)",
      "es-mx": "Mexican Spanish (MX)",
      "fr-ca": "French Canadian (CA)"
    }
  }], ["en-us", {
    "alarm": {
      "details": {
        "faults": {
          "lockout": "Lockout Type",
          "reported": "Reported Time",
          "resolution": "Resolution"
        },
        "raisedAt": {
          "label": "Alarm Time",
          "tip": "{date}"
        },
        "resolvedAt": {
          "label": "Alarm Resolved Time",
          "tip": "{date}"
        },
        "severity": {
          "label": "Severity",
          "tip": "Severity of the alarm been raised"
        },
        "summary": {
          "label": "Alarm Details",
          "tip": "Details of the alarm been raised"
        },
        "type": {
          "label": "Alarm",
          "tip": "Type of alarm raised"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Alarm Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Alarm"
      },
      "filter": {
        "clear": "Clear Filter",
        "severity": {
          "label": "Severity",
          "placeholder": "Alarm Severity",
          "tip": "Severity of the alarm been raised"
        },
        "status": {
          "label": "Connection Status",
          "placeholder": "Connection Status",
          "tip": "Device's connection status associated with an alarm"
        },
        "submit": "Update Filter",
        "title": "Filter Alarms"
      },
      "form": {
        "raisedAt": {
          "label": "Alarm Time",
          "tip": "When was the alarm been raised"
        },
        "severity": {
          "label": "Severity",
          "tip": "Severity of the alarm been raised"
        },
        "summary": {
          "label": "Alarm Details",
          "tip": "Details of the alarm been raised"
        },
        "type": {
          "label": "Alarm",
          "tip": "Type of alarm raised"
        }
      },
      "list": {
        "actions": {
          "filter": "Filter",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Alarms",
        "empty": "No alarms found",
        "messages": {
          "export": "Export Started"
        },
        "resolvedCrumb": "Resolved Alarms",
        "showResolved": "Show resolved alarms",
        "showUnresolved": "Show unresolved alarms",
        "title": "List Alarms",
        "titleResolved": "List Resolved Alarms"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Alarms"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Alarm Created",
          "validation": "Validation Error"
        },
        "title": "New Alarm"
      },
      "severity": {
        "crit": "Critical",
        "info": "Info",
        "warn": "Warning"
      },
      "status": {
        "offline": "Offline",
        "online": "Online"
      },
      "telemetry": {
        "button": "Alarm Telemetry",
        "crumb": "Telemetry",
        "navTitle": "Telemetry",
        "title": "Alarm Telemetry",
        "titleResolved": "Resolved Alarm Telemetry"
      },
      "view": {
        "alarm-details": "Alarm Details",
        "crumb": "View",
        "deviceNavTitle": "Device",
        "navTitle": "View",
        "title": "View Alarm",
        "titleResolved": "View Resolved Alarm"
      }
    },
    "archive": {
      "confirm": "Confirm",
      "title": "Are you sure you want to {action} this",
      "titleShort": "Are you sure?"
    },
    "dashboard": {
      "alarms": {
        "label": "Alarm",
        "severity": "Severity"
      },
      "awsStatus": {
        "cardTitle": "System Status",
        "degraded": {
          "description": "Users may see degraded performance in the apps and on the portal",
          "status": "Degraded"
        },
        "degradedService": "Degraded Service",
        "messagesTitle": "System Messages",
        "online": {
          "description": "All AWS services online",
          "message": "All AWS services are currently online and available",
          "status": "Online"
        },
        "outage": {
          "description": "Users may not be able to access the apps or the portal",
          "message": "We can`t connect to AWS, some or all services may be unavailable",
          "status": "Outage"
        },
        "title": "AWS Status"
      },
      "devices": {
        "serialNumber": "TSync Serial Number"
      },
      "loading": "Loading…",
      "refresh": "Refresh",
      "titles": {
        "alarmSummary": "Alarm Summary",
        "installationSummary": "Installation Summary",
        "recentAddDevices": "Recently added devices:",
        "recentAlarms": "Recently raised alarms:",
        "serviceDue": "Service Due"
      }
    },
    "device": {
      "alarm": {
        "crumb": "Alarm",
        "list": {
          "actions": {
            "filter": "Filter",
            "search": "Search",
            "sort": {
              "label": "Sort",
              "options": {
                "raisedAt": "Raised At",
                "resolvedAt": "Resolved At",
                "severity": "Alarm Severity"
              }
            }
          },
          "crumb": "Alarms",
          "empty": "No alarms found",
          "filtered": "Displaying Filtered Results",
          "item": {
            "description": "Description",
            "raisedAt": "Raised at",
            "resolvedAt": "Resolved at"
          },
          "title": "Device Alarms"
        },
        "navTitle": "Alarm",
        "title": "Alarm"
      },
      "alarms": {
        "actions": {
          "filter": "Filter",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "lastUpdated": "Last Updated",
              "name": "Team Name"
            }
          }
        },
        "crumb": "Alarms",
        "navTitle": "Alarms",
        "showResolved": "Show resolved alarms",
        "showUnresolved": "Show unresolved alarms",
        "title": "Device Alarms",
        "titleResolved": "Resolved Device Alarms"
      },
      "archive": {
        "body": "<p>This will archive the Device “<b>{name}</b>”.</p><p>This Device will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "bodyInstallerPrivate": "<p>This will remove your access to the Device “<b>{name}</b>”.</p><p>This Device will no longer appear in your list of devices. All data will be hidden and require a manual process to restore.</p>",
        "button": "Archive Device",
        "buttonInstallerPrivate": "Remove Access",
        "crumb": "Archive",
        "crumbInstallerPrivate": "Remove",
        "messages": {
          "server": "Server Error",
          "success": "Device Archived"
        },
        "navTitle": "Archive",
        "navTitleInstallerPrivate": "Remove",
        "title": "Archive Device",
        "titleInstallerPrivate": "Remove Access"
      },
      "boiler-service": {
        "crumb": "Boiler Service",
        "details": "Boiler Service Details",
        "navTitle": "Boiler Service",
        "title": "Boiler Service"
      },
      "data-visualisation": {
        "crumb": "Data Visualisation",
        "loadingState": "Gathering Chart Data",
        "navTitle": "Data Visualisation",
        "title": "Data Visualisation"
      },
      "details": {
        "archivedAt": {
          "label": "Archived at"
        },
        "boilerBrand": {
          "label": "Boiler Brand",
          "tip": "The boiler brand"
        },
        "boilerSerialNumber": {
          "label": "Boiler Serial Number",
          "tip": "The serial number of the boiler"
        },
        "boilerType": {
          "label": "Boiler Model",
          "tip": "The boiler model"
        },
        "lastServiceAt": {
          "label": "Last Service at",
          "tip": "{date}"
        },
        "nextServiceDue": {
          "label": "Next Service Due",
          "tip": "{date}"
        },
        "property": {
          "label": "Property",
          "tip": "The property the device is installed at"
        },
        "registeredAt": {
          "label": "Registered at",
          "tip": "{date}"
        },
        "registeredBy": {
          "label": "Registered by",
          "tip": "The portal user who registered this device"
        },
        "registrationType": {
          "label": "Registration Type",
          "tip": "The registration type (Social/Private)"
        },
        "serviceIntervalDays": {
          "label": "Service Interval Days",
          "tip": "Recommended number of days between services"
        }
      },
      "edit": {
        "crumb": "Boiler Information",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Device Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Boiler Information",
        "title": "Boiler Information"
      },
      "filter": {
        "Installation": {
          "label": "Installed In",
          "placeholder": "Last 30 Days",
          "tip": "Whether a device is installed in the last 30 days"
        },
        "ServiceDue": {
          "label": "Service Due",
          "placeholder": "Overdue",
          "tip": "Whether a device is due for a service"
        },
        "archive": {
          "label": "Archived",
          "placeholder": "Exclude",
          "tip": "Include or exclude archived/unregistered devices"
        },
        "boilerType": {
          "label": "Boiler model",
          "placeholder": "All Boiler Model",
          "tip": "The type of boiler"
        },
        "clear": "Clear Filter",
        "hasAlarms": {
          "label": "Alarms",
          "placeholder": "Any",
          "tip": "Whether a device has alarms"
        },
        "hasFaults": {
          "label": "Faults",
          "placeholder": "Any",
          "tip": "Whether a device has faults"
        },
        "priority": {
          "label": "Priority Properties",
          "placeholder": "Any",
          "tip": "Only show devices assigned to priority properties"
        },
        "status": {
          "label": "Device status",
          "placeholder": "All Status'",
          "tip": "Whether the device is currently online"
        },
        "submit": "Update Filter",
        "type": {
          "label": "Module type",
          "placeholder": "All Module Types",
          "tip": "The type of module installed in the boiler"
        }
      },
      "form": {
        "boilerSerialNumber": {
          "editTip": "The serial number of the boiler",
          "label": "Boiler Serial Number",
          "placeholder": null,
          "tip": "The serial number of the boiler (optional)"
        },
        "boilerType": {
          "label": "Boiler Model",
          "placeholder": "Select boiler model",
          "tip": "The type of boiler (optional)"
        },
        "footer": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "lastServiceAt": {
          "label": "Last Service At",
          "placeholder": "dd/mm/yyyy",
          "tip": "The last time the boiler was serviced"
        },
        "nextServiceAt": {
          "label": "Next Service At",
          "placeholder": "dd/mm/yyyy",
          "tip": "The next time the boiler needs to be serviced"
        },
        "performService": {
          "button": "Perform Service",
          "confirm": "Confirm",
          "message": "By clicking \"Confirm\" you are acknowledging that you have performed a service on this boiler and agreed on the next service date.",
          "nextServiceLabel": "Next service due on:",
          "servicePerformedLabel": "Service performed on:",
          "success": "Service Period Updated",
          "title": "Perform Service",
          "updateBoiler": "Update Boiler Information"
        },
        "serviceInterval": {
          "label": "Service Interval",
          "placeholder": "365",
          "tip": "The number of days between boiler services"
        }
      },
      "gas-consumption": {
        "boilerModel": {
          "label": "Boiler Model",
          "tip": "The model of the boiler"
        },
        "dayPowerUseage": {
          "label": "24 Hour Total (KWH)",
          "tip": "The amount of power used in 24 hours"
        },
        "detail": {
          "body": "<p>To see how we calculate the total gas consumption used for devices, and validate the estimated values produced by the equation email us at enquires@idealheating.com</p>",
          "title": "Details"
        },
        "detail-summary": {
          "title": "Summary"
        },
        "header": "Estimated Gas Consumption Calculation",
        "maxChFanSpeed": {
          "label": "Max CH Fan Speed",
          "tip": "The maximum central heating fan speed"
        },
        "maxDhwFanSpeed": {
          "label": "Max DHW Fan Speed",
          "tip": "The maximum domestic hot water fan speed"
        },
        "minFanSpeed": {
          "label": "Min Fan Speed",
          "tip": "The minimum fan speed"
        },
        "monthPowerUseage": {
          "label": "Month Total (KWH)",
          "tip": "The amount of power used in a month"
        },
        "table": {
          "header": {
            "date": "Date",
            "fanAggregate": "Fan Aggregate (Ch)",
            "flameOnDuration": "Flame on Duration (Ch)",
            "gasConsumption": "Calculated Gas Consumption (kWh)",
            "time": "Time"
          }
        },
        "weekPowerUseage": {
          "label": "Week Total (KWH)",
          "tip": "The amount of power used in a week"
        },
        "yearPowerUseage": {
          "label": "Year Total (KWH)",
          "tip": "The amount of power used in a year"
        }
      },
      "license": {
        "cloudConnectionToggle": {
          "label": "Cloud Connection",
          "tip": "This device has access to app & cloud features"
        },
        "footer": {
          "cancel": "Cancel",
          "save": "Save",
          "server": "Server Error",
          "validation": "Validation Error"
        },
        "messages": {
          "cancel": "License changes cancelled",
          "server": "Server error",
          "success": "License details successfully changed",
          "validation": "Validation Error"
        },
        "notes": {
          "label": "Notes",
          "placeholder": "Note",
          "tip": "Add any additional notes"
        },
        "paymentReference": {
          "label": "Payment reference",
          "placeholder": "Payment reference",
          "tip": "The payment reference of the license"
        },
        "supportTip": "Before activating the upgrade ensure the customer closes the app"
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Devices"
          },
          "filter": "Filter",
          "new": "Add New Device",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "lastServiceAt": "Service date",
              "priority": "Priority Property",
              "property": "Property (ZIP Code)"
            }
          },
          "view-map": "View map"
        },
        "crumb": "Devices",
        "empty": "No devices found",
        "filtered": "Displaying Filtered Results",
        "item": {
          "alarm": "alarm",
          "archived": "Archived",
          "noAlarm": "No alarm",
          "offline": "Disconnected",
          "online": "Connected",
          "service": "Service Due",
          "zip": "ZIP Code"
        },
        "messages": {
          "export": "Export Started"
        },
        "title": "List Devices"
      },
      "live-data": {
        "configurationTitle": "Configuration data",
        "connected": "Connnected",
        "connection": "Connection",
        "deviceOffline": "The device is currently offline",
        "highPriorityTitle": "Priority Information",
        "liveDataTitle": "Live data",
        "lowPriorityTitle": "Extra Information",
        "lowReportingRate": "The device has not updated it's reporting rate, live updates will not appear",
        "notConnected": "Not Connected",
        "title": "Live Data",
        "websocketNotConnected": "Websocket not connected, attempting to reconnect"
      },
      "logic-details": {
        "appVersion": {
          "label": "Firmware Version",
          "tip": "The device's app firmware version"
        },
        "boilerProtocol": {
          "label": "Boiler Protocol",
          "tip": "The device's boiler protocol"
        },
        "hardwareVersion": {
          "label": "Hardware Version",
          "tip": "The device's hardware version"
        },
        "network": {
          "label": "Network Name",
          "tip": "The name of the network connected to"
        },
        "networkAccessTechnology": {
          "label": "Network Access Technology",
          "tip": "The method used to connect to the cloud"
        },
        "networkRssi": {
          "label": "Network RSSI",
          "tip": "The device's local signal strength"
        },
        "resetReason": {
          "label": "Reset Reason",
          "tip": "The reason the device last reset"
        },
        "serialNumber": {
          "label": "TSync Serial Number",
          "tip": "The devices unique serial"
        },
        "status": {
          "label": "Connectivity",
          "tip": "Device connectivity to the cloud"
        },
        "telemetryInterval": {
          "label": "Telemetry Interval",
          "tip": "The rate that telemetry is current configured to be sent at"
        }
      },
      "map": {
        "actions": {
          "view-list": "View list"
        },
        "crumb": "Map",
        "device-details": {
          "boilerBrand": {
            "label": "Boiler Brand",
            "tip": "The boiler brand"
          },
          "boilerType": {
            "label": "Boiler Model",
            "tip": "The boiler model"
          },
          "lastSeenAt": {
            "label": "Last Reported In",
            "tip": "When the device last reported in"
          },
          "lastServiceAt": {
            "label": "Last Service at",
            "tip": "{date}"
          },
          "moduleType": {
            "label": "Module Type",
            "tip": "The module type (Logic, iD-C, Halo, Halo Air)"
          },
          "property": {
            "label": "Address",
            "tip": "The address of property the device is installed at"
          },
          "registeredAt": {
            "label": "Registered at",
            "tip": "{date}"
          },
          "serialNumber": {
            "label": "Serial Number",
            "tip": "The devices unique serial"
          },
          "status": {
            "label": "Connectivity",
            "tip": "Device connectivity to the cloud"
          },
          "viewDevice": {
            "label": "View Device"
          }
        },
        "navTitle": "Map",
        "title": "Map Devices"
      },
      "map-view": {
        "crumb": "Map View",
        "navTitle": "Map View",
        "title": "Device Location"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Devices"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Device Created",
          "validation": "Validation Error"
        },
        "title": "New Device"
      },
      "system-dashboard": {
        "crumb": "System Dashboard",
        "navTitle": "System Dashboard",
        "title": "System Dashboard"
      },
      "telemetry": {
        "average": {
          "label": "Average",
          "tip": "The average value of telemetry metrics"
        },
        "boilerInformation": "Boiler Information",
        "boilerSetpoint": "Boiler Setpoint",
        "boilerStatus": "Boiler Status",
        "chIgnitions": "CH Ignitions",
        "chRuntime": "CH Runtime",
        "ch_return_temperature": "Return Temp",
        "ch_water_pressure": "Water Pressure",
        "count": {
          "label": "Count",
          "tip": "The number of telemetry metrics received"
        },
        "dhwCall": "DHW Call",
        "dhwIgnitions": "DHW Ignitions",
        "dhwRuntime": "DHW Runtime",
        "dhw_temperature": "DHW Temp",
        "exhaust_temperature": "Boiler Flue Temp",
        "flame_current": "Ionization Current",
        "flow_water_temperature": "Supply Temp",
        "heatingCall": "Heating Call",
        "item": {
          "null": "N/A"
        },
        "lastChanged": "Last Changed",
        "loading": "Loading Telemetry…",
        "max": {
          "label": "Max",
          "tip": "The maximum value of telemetry metrics"
        },
        "min": {
          "label": "Min",
          "tip": "The minimum value of telemetry metrics"
        },
        "outside_temperature": "Outside Temp",
        "relative_modulation_level": "Boiler Firing Rate",
        "title": "Telemetries"
      },
      "view": {
        "crumb": "View",
        "details": "Boiler Details",
        "device-details": "TSync Details",
        "navTitle": "View",
        "priority": "Priority",
        "property": "Property",
        "title": "View Device",
        "togglePriority": "Toggle Priority"
      }
    },
    "external": {
      "invite": {
        "byline": "Set a password to start using the Triangle Tube Portal",
        "title": "Register your account"
      },
      "login": {
        "byline": "Or contact an administrator if you do not have an account",
        "form": {
          "email": {
            "label": "Email",
            "placeholder": "name@example.com",
            "tip": "This is usually your work email address"
          },
          "forgot": "Forgot Password?",
          "newUser": "Create an account?",
          "password": {
            "label": "Password",
            "placeholder": "Enter your password",
            "tip": "Your password to the portal"
          },
          "submit": "Login"
        },
        "messages": {
          "incorrectEmailOrPassword": "Incorrect email or password entered, please check spelling and try again",
          "server": "Server Error",
          "success": "Welcome!",
          "unknownUser": "User does not exist",
          "validation": "Incorrect email or password entered, please check spelling and try again"
        },
        "title": "Sign in to your account"
      },
      "maintenance": {
        "description": "The site is currently down for maintenance, please try again later.",
        "title": "Down for maintenance"
      },
      "new-password": {
        "byline": "Set a password to start using the Triangle Tube Portal",
        "form": {
          "back": "Back to Login",
          "password": {
            "label": "New Password",
            "placeholder": "Enter a new password",
            "tip": "Passwords must be at least 8 characters long, must contain at least one lowercase character, uppercase character, special character and number"
          },
          "passwordConfirmation": {
            "label": "Confirm Password",
            "placeholder": "Enter new password",
            "tip": "Please confirm your new password"
          },
          "submit": "Set Password"
        },
        "messages": {
          "server": "Server Error",
          "success": "Password Updated",
          "validation": "Validation Error"
        },
        "title": "Set your password"
      },
      "recover": {
        "byline": "No problem, enter your account's email and we'll send a reset link to your inbox.",
        "form": {
          "back": "Back to Login",
          "email": {
            "label": "Email",
            "placeholder": "name@example.com",
            "tip": "This is usually your work email address"
          },
          "submit": "Send Password Email"
        },
        "messages": {
          "server": "Server Error",
          "success": "Password Email Sent",
          "unknownUser": "User does not exist",
          "validation": "Validation Error"
        },
        "title": "Forgot your password?"
      },
      "reset": {
        "byline": "Almost there! Set a new password to access the Triangle Tube Portal again",
        "form": {
          "back": "Back to Login",
          "password": {
            "label": "New Password",
            "placeholder": "Enter a new password",
            "tip": "Passwords must be at least 8 characters long, must contain at least one lowercase character, uppercase character, special character and number"
          },
          "passwordConfirmation": {
            "label": "Confirm Password",
            "placeholder": "Enter new password",
            "tip": "Please confirm your new password"
          },
          "submit": "Change Password",
          "verificationCode": {
            "label": "Verification Code",
            "placeholder": "Enter your verification code",
            "tip": "This will have been emailed to you"
          }
        },
        "messages": {
          "server": "Server Error",
          "success": "Password Updated",
          "validation": "Validation Error"
        },
        "title": "Reset your password"
      },
      "splashImageAlt": "Image of wind turbines at sea"
    },
    "footer": {
      "version": "Version"
    },
    "form": {
      "footer": {
        "cancel": "Cancel",
        "save": "Save",
        "serverErrors": "Server Error",
        "validationErrors": "Validation Errors"
      }
    },
    "home": {
      "nav": {
        "label": "Home"
      },
      "search": {
        "button": "Search it",
        "deviceHeader": "Search for devices:",
        "devicePlaceholder": "Search by serial number or address",
        "homeownerHeader": "Search for customers:",
        "homeownerPlaceholder": "Search by customer name, serial number or MAC address"
      },
      "title": "Welcome to the Tsync portal"
    },
    "ideal-user": {
      "archive": {
        "body": "<p>This will archive the Customer Service User “<b>{name}</b>”.</p><p>This Customer Service User will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Customer Service User",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Customer Service User Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Customer Service User"
      },
      "details": {
        "createdAt": {
          "label": "Created at",
          "tip": "When the user was created"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "The full name of the user who invited this user"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "The Id of the user who invited this user"
        },
        "email": {
          "label": "Email",
          "tip": "The user's email address"
        },
        "firstName": {
          "label": "First Name",
          "tip": "The user's first name"
        },
        "lastActive": {
          "label": "Last Active",
          "tip": "When the user last used the portal"
        },
        "lastName": {
          "label": "Last Name",
          "tip": "The user's last name"
        },
        "notes": {
          "label": "Notes",
          "tip": "Optional notes associated with the user"
        },
        "role": {
          "label": "Role",
          "tip": "The user's role"
        },
        "updatedAt": {
          "label": "Updated at",
          "tip": "When the user was updated"
        },
        "updatedBy": {
          "label": "Updated by",
          "tip": "The full name of the user who updated this user"
        },
        "updatedById": {
          "label": "Updated by Id",
          "tip": "The Id of the user who updated this user"
        },
        "verified": {
          "label": "Verified",
          "tip": "Whether the user has verified their email address"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Customer Service User Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Customer Service User"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "addressLine1": {
          "label": "Address",
          "placeholder": "Enter your Address",
          "tip": "Your Address"
        },
        "email": {
          "label": "Email",
          "placeholder": "Enter Email",
          "tip": "This is usually an invitees work email address"
        },
        "firstName": {
          "label": "First Name",
          "placeholder": "Enter First Name",
          "tip": "The invitees first name"
        },
        "lastName": {
          "label": "Last Name",
          "placeholder": "Enter Last Name",
          "tip": "The invitees last name"
        },
        "role": {
          "label": "Role",
          "placeholder": "Select Role",
          "tip": "The permission level of the invitee"
        },
        "roles": {
          "admin": "Admin",
          "engineer": "Engineer",
          "support": "Support"
        }
      },
      "list": {
        "actions": {
          "filter": "Filter",
          "new": "Add User",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Users",
        "messages": {
          "export": "Export Started"
        },
        "title": "Customer Service Users"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Users"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Customer Service User Created",
          "validation": "Validation Error"
        },
        "title": "Customer Support Users"
      },
      "roles": {
        "Admin": "Admin",
        "Engineer": "Engineer",
        "User": "Support"
      },
      "view": {
        "crumb": "View",
        "navTitle": "View",
        "title": "View Customer Service User"
      }
    },
    "internal": {
      "linkPrefix": "Provided by"
    },
    "languages": {
      "en-uk": "English (UK)",
      "en-us": "American English (US)",
      "es-mx": "Mexican Spanish (MX)",
      "fr-ca": "French Canadian (CA)"
    },
    "modal": {
      "cancel": "Cancel"
    },
    "nav": {
      "home": "Home",
      "logout": "Logout",
      "partners": "Groups",
      "settings": "Settings"
    },
    "notFound": {
      "button": "Go Home",
      "subtitle": "Oops. This page has gone missing.",
      "text": "You may have mistyped the address or the page may have moved.",
      "title": "404"
    },
    "pagination": {
      "label": "Results per page"
    },
    "partner": {
      "archive": {
        "body": "<p>This will archive the Group “<b>{name}</b>”.</p><p>This Group will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Group",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Group Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Group"
      },
      "details": {
        "addressCity": {
          "label": "City"
        },
        "addressCountry": {
          "label": "Country"
        },
        "addressCounty": {
          "label": "County"
        },
        "addressLine1": {
          "label": "Address Line 1"
        },
        "addressLine2": {
          "label": "Address Line 2"
        },
        "addressPostcode": {
          "label": "ZIP Code"
        },
        "contactNumber": {
          "label": "Contact Number"
        },
        "createdAt": {
          "label": "Created",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "This is a tip for Createdbyid"
        },
        "name": {
          "label": "Name",
          "tip": "This is a tip for Name"
        },
        "propertyCount": {
          "label": "Property Count",
          "tip": "This is a tip for Propertycount"
        },
        "switchContext": {
          "cancel": "Cancel",
          "confirm": "Confirm",
          "message": "You are about to switch to a group context and view the portal as {partner}. Do you wish to continue?",
          "title": "Are you sure?"
        },
        "updatedAt": {
          "label": "Updated",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "tip": "This is a tip for Updatedbyid"
        },
        "userCount": {
          "label": "Number of users",
          "tip": "This is a tip for Usercount"
        },
        "website": {
          "label": "Website"
        }
      },
      "edit": {
        "crumb": "Edit",
        "label": "Group",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Group Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Group"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "addressCity": {
          "label": "City",
          "placeholder": "City",
          "tip": null
        },
        "addressCountry": {
          "label": "Country",
          "placeholder": "Country",
          "tip": null
        },
        "addressCounty": {
          "label": "County",
          "placeholder": "County",
          "tip": null
        },
        "addressLine1": {
          "label": "1st Line of Address",
          "placeholder": "Address Line 1"
        },
        "addressLine2": {
          "label": "2nd Line of Address",
          "placeholder": "Address Line 2"
        },
        "addressPostcode": {
          "label": "ZIP Code",
          "placeholder": "ZIP Code"
        },
        "contactNumber": {
          "label": "Contact Number",
          "placeholder": "Enter Contact Number",
          "tip": "This is a central contact number for the group"
        },
        "name": {
          "label": "Group Name",
          "placeholder": "Enter Name",
          "tip": "This is the name for the entire group"
        },
        "website": {
          "label": "Website",
          "placeholder": "Website"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Groups"
          },
          "filter": "Filter",
          "new": "Add New Group",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "name": "Name",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Groups",
        "messages": {
          "export": "Export Started"
        },
        "title": "List Groups"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Groups"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Group Created",
          "validation": "Validation Error"
        },
        "title": "New Group"
      },
      "view": {
        "crumb": "View",
        "details": "Group Details",
        "navTitle": "View",
        "title": "View Group"
      }
    },
    "partner-user": {
      "archive": {
        "body": "<p>This will archive the User “<b>{name}</b>”.</p><p>This User will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive User",
        "confirmDescription": "By clicking \"Confirm\" you are acknowledging that you are performing an archive action and any archived data requires a manual process to restore.",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "User Archived"
        },
        "navTitle": "Archive",
        "title": "Archive User"
      },
      "details": {
        "cognitoUpdatedAt": {
          "label": "Cognito Last Updated"
        },
        "createdAt": {
          "label": "Created at",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "This is a tip for Createdbyid"
        },
        "email": {
          "label": "Email",
          "tip": "This is usually an invitees work email address"
        },
        "emailVerified": {
          "label": "Email Verified"
        },
        "firstName": {
          "label": "First Name",
          "tip": "The invitees first name"
        },
        "lastActive": {
          "label": "Last Active",
          "tip": "This is a tip for Lastactive"
        },
        "lastName": {
          "label": "Last Name",
          "tip": "The invitees last name"
        },
        "notes": {
          "label": "Notes",
          "tip": "Optional notes associated with the user"
        },
        "partner": {
          "label": "Partner",
          "tip": "This is a tip for Partner"
        },
        "role": {
          "label": "Role",
          "tip": "The user's role"
        },
        "status": {
          "label": "Status",
          "tip": "The Cognito Status"
        },
        "updatedAt": {
          "label": "Updated at",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "tip": "This is a tip for Updatedbyid"
        },
        "verified": {
          "label": "Verified",
          "tip": "Whether the user has completed user sign up"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "User Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Partner User"
      },
      "email": {
        "crumb": "Email Notifications",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "User Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Email Notifications",
        "title": "Edit User Email Notifications"
      },
      "filter": {
        "clear": "Clear Filter",
        "email": {
          "label": "Email",
          "placeholder": "name@example.com",
          "tip": "The target email address"
        },
        "firstName": {
          "label": "First name",
          "placeholder": "Enter first name",
          "tip": "The target first name"
        },
        "lastName": {
          "label": "Last name",
          "placeholder": "Enter last name",
          "tip": "The target last name"
        },
        "partnerRole": {
          "label": "Role",
          "placeholder": "Enter role",
          "tip": "The target role"
        },
        "submit": "Update Filter"
      },
      "form": {
        "createdAt": {
          "label": "Created at",
          "placeholder": "Enter Createdat",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "placeholder": "Enter Createdby",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "placeholder": "Enter Createdbyid",
          "tip": "This is a tip for Createdbyid"
        },
        "email": {
          "label": "Email",
          "placeholder": "Enter Email",
          "tip": "This is a tip for Email"
        },
        "firstName": {
          "label": "First Name",
          "placeholder": "Enter Firstname",
          "tip": "This is a tip for Firstname"
        },
        "lastActive": {
          "label": "Last Active",
          "placeholder": "Enter Lastactive",
          "tip": "This is a tip for Lastactive"
        },
        "lastName": {
          "label": "Last Name",
          "placeholder": "Enter Lastname",
          "tip": "This is a tip for Lastname"
        },
        "notes": {
          "label": "Notes",
          "placeholder": "Enter Notes",
          "tip": "This is a tip for Notes"
        },
        "partner": {
          "label": "Partner",
          "placeholder": "Enter Partner",
          "tip": "This is a tip for Partner"
        },
        "partnerRole": {
          "label": "Partner Role",
          "placeholder": "Enter Partnerrole",
          "tip": "This is a tip for Partnerrole"
        },
        "role": {
          "label": "Role",
          "placeholder": "Select Role",
          "tip": "The permission level of the invitee"
        },
        "roles": {
          "admin": "Admin",
          "user": "User"
        },
        "updatedAt": {
          "label": "Updated at",
          "placeholder": "Enter Updatedat",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "placeholder": "Enter Updatedby",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "placeholder": "Enter Updatedbyid",
          "tip": "This is a tip for Updatedbyid"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Users"
          },
          "filter": "Filter",
          "new": "Add User",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          },
          "upload": "Upload Users"
        },
        "crumb": "Users",
        "messages": {
          "export": "Export Started"
        },
        "roles": {
          "Admin": "Admin",
          "User": "User"
        },
        "title": "Users"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Users"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "User Created",
          "validation": "Validation Error"
        },
        "title": "New User"
      },
      "resend": {
        "server": "Unable to resend at the moment",
        "success": "Password resent"
      },
      "roles": {
        "admin": "Admin",
        "user": "User"
      },
      "view": {
        "cognitoTitle": "User Authentication Status",
        "crumb": "View",
        "navTitle": "View",
        "resendPasswordButton": "Resend password",
        "title": "View User",
        "user-details": "User Details"
      }
    },
    "property": {
      "archive": {
        "body": "<p>This will archive the Property “<b>{name}</b>”.</p><p>This Property will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Property",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Property Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Property"
      },
      "details": {
        "addressCity": {
          "label": "Address City",
          "tip": "This is a tip for Addresscity"
        },
        "addressCountry": {
          "label": "Address Country",
          "tip": "This is a tip for Addresscountry"
        },
        "addressCounty": {
          "label": "Address County",
          "tip": "This is a tip for Addresscounty"
        },
        "addressLine1": {
          "label": "Address Line1",
          "tip": "This is a tip for Addressline1"
        },
        "addressPostcode": {
          "label": "Address Zip Code",
          "tip": "This is a tip for AddressZipCode"
        },
        "createdAt": {
          "label": "Created at",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "This is a tip for Createdbyid"
        },
        "latitude": {
          "label": "Latitude",
          "tip": "This is a tip for Latitude"
        },
        "notes": {
          "label": "Notes",
          "tip": "This is a tip for Notes"
        },
        "partner": {
          "label": "Group",
          "tip": "This is a tip for Group"
        },
        "propertyCode": {
          "label": "Property Code",
          "tip": "This is a tip for Propertycode"
        },
        "updatedAt": {
          "label": "Updated at",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "tip": "This is a tip for Updatedbyid"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Property Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Property"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "addressCity": {
          "label": "Address City",
          "placeholder": "Enter Addresscity",
          "tip": "This is a tip for Addresscity"
        },
        "addressCountry": {
          "label": "Address Country",
          "placeholder": "Enter Addresscountry",
          "tip": "This is a tip for Addresscountry"
        },
        "addressCounty": {
          "label": "Address County",
          "placeholder": "Enter Addresscounty",
          "tip": "This is a tip for Addresscounty"
        },
        "addressLine1": {
          "label": "Address Line1",
          "placeholder": "Enter Addressline1",
          "tip": "This is a tip for Addressline1"
        },
        "addressPostcode": {
          "label": "Address Zip Code",
          "placeholder": "Enter AddressZipCode",
          "tip": "This is a tip for AddressZipCode"
        },
        "createdAt": {
          "label": "Created at",
          "placeholder": "Enter Createdat",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "placeholder": "Enter Createdby",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "placeholder": "Enter Createdbyid",
          "tip": "This is a tip for Createdbyid"
        },
        "latitude": {
          "label": "Latitude",
          "placeholder": "Enter Latitude",
          "tip": "This is a tip for Latitude"
        },
        "notes": {
          "label": "Notes",
          "placeholder": "Enter Notes",
          "tip": "This is a tip for Notes"
        },
        "partner": {
          "label": "Group",
          "placeholder": "Enter Group",
          "tip": "This is a tip for Group"
        },
        "propertyCode": {
          "label": "Property Code",
          "placeholder": "Enter Propertycode",
          "tip": "This is a tip for Propertycode"
        },
        "updatedAt": {
          "label": "Updated at",
          "placeholder": "Enter Updatedat",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "placeholder": "Enter Updatedby",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "placeholder": "Enter Updatedbyid",
          "tip": "This is a tip for Updatedbyid"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Properties"
          },
          "filter": "Filter",
          "new": "Add New Property",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Properties",
        "messages": {
          "export": "Export Started"
        },
        "title": "List Properties"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Properties"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Property Created",
          "validation": "Validation Error"
        },
        "title": "New Property"
      },
      "view": {
        "crumb": "View",
        "navTitle": "View",
        "title": "View Property"
      }
    },
    "search": {
      "placeholder": "Search"
    },
    "session-partner": {
      "form": {
        "criticalAlertSub": {
          "label": "Critical",
          "tip": "Subscribe to critical alert emails"
        },
        "emailsEnabled": {
          "label": "Enabled/Disable",
          "tip": "Enable or disable emails"
        },
        "footer": {
          "cancel": "Cancel",
          "save": "Save Changes"
        },
        "infoAlertSub": {
          "label": "Info",
          "tip": "Subscribe to info alert emails"
        },
        "warningAlertSub": {
          "label": "Warning",
          "tip": "Subscribe to warning alert emails"
        }
      }
    },
    "settings": {
      "attributes": {
        "addressLine1": {
          "label": "Address",
          "placeholder": "Enter your Address",
          "tip": "Your Address"
        },
        "crumb": "Change Name",
        "family_name": {
          "label": "Last Name",
          "placeholder": "Enter your Last Name",
          "tip": "Your Last Name"
        },
        "given_name": {
          "label": "First Name",
          "placeholder": "Enter your First Name",
          "tip": "Your First Name"
        },
        "navTitle": "Change Name",
        "title": "Change Name",
        "userDetailsLink": "Change Name"
      },
      "crumb": "Settings",
      "emailPreferences": {
        "crumb": "Email Preferences",
        "emailPreferencesLink": "Email Preferences",
        "messages": {
          "cancel": "Settings changes cancelled",
          "server": "Server error",
          "success": "Settings successfully changed"
        },
        "navTitle": "Email Preferences",
        "title": "Email Preferences for {name}"
      },
      "flags": {
        "debug": "Debug",
        "header": "User Flags"
      },
      "group": {
        "navTitle": "Group"
      },
      "header": "Preferences",
      "navTitle": "User Flags",
      "password": {
        "changePasswordLink": "Change Password",
        "crumb": "Change Password",
        "messages": {
          "cancel": "Password change cancelled",
          "server": "Server error",
          "success": "Password successfully changed"
        },
        "navTitle": "Change Password",
        "oldPassword": {
          "label": "Current Password",
          "placeholder": "Password",
          "tip": "Your existing password to the portal"
        },
        "password": {
          "label": "New Password",
          "placeholder": "New Password",
          "tip": "Passwords must be at least 8 characters long, must contain at least one lowercase character, uppercase character, special character and number"
        },
        "passwordConfirmation": {
          "label": "Confirm Password",
          "placeholder": "Confirm Password",
          "tip": "Confirmation of the new password"
        },
        "submit": "Save",
        "title": "Change Password"
      },
      "title": "User Flags",
      "userDetails": {
        "messages": {
          "cancel": "User detail changes cancelled",
          "server": "Server error",
          "success": "User details successfully changed",
          "validation": "Validation Error"
        }
      }
    },
    "user": {
      "customerService": "Customer Service"
    },
    "webhook": {
      "archive": {
        "body": "<p>This will archive the Webhook “<b>{name}</b>”.</p><p>This Webhook will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Webhook",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Webhook Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Webhook"
      },
      "details": {
        "EventTypes": {
          "label": "Event Types",
          "tip": "This is a tip for Eventtypes"
        },
        "Name": {
          "label": "Name",
          "tip": "This is a tip for Name"
        },
        "Url": {
          "label": "Url",
          "tip": "This is a tip for Url"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Webhook Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Webhook"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "EventTypes": {
          "label": "Event Types",
          "placeholder": "Enter Eventtypes",
          "tip": "This is a tip for Eventtypes"
        },
        "Name": {
          "label": "Name",
          "placeholder": "Enter Name",
          "tip": "This is a tip for Name"
        },
        "Url": {
          "label": "Url",
          "placeholder": "Enter Url",
          "tip": "This is a tip for Url"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Webhooks"
          },
          "filter": "Filter",
          "new": "Add New Webhook",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Webhooks",
        "messages": {
          "export": "Export Started"
        },
        "title": "Webhooks"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Webhooks"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Webhook Created",
          "validation": "Validation Error"
        },
        "title": "New Webhook"
      },
      "view": {
        "crumb": "View",
        "navTitle": "View",
        "title": "View Webhook"
      }
    }
  }], ["es-mx", {
    "footer": {
      "version": "Versión"
    },
    "home": {
      "title": "Bienvenido al portal de Triangle Tube"
    },
    "languages": {
      "en-uk": "Inglesa (UK)",
      "en-us": "Inglés Americano (US)",
      "es-mx": "Española Mexicana (MX)",
      "fr-ca": "Francesa Canadiense (CA)"
    }
  }], ["fr-ca", {
    "footer": {
      "version": "Version"
    },
    "home": {
      "title": "Bienvenue sur le portail Triangle Tube"
    },
    "languages": {
      "en-uk": "Anglaise (UK)",
      "en-us": "Anglais Américain (US)",
      "es-mx": "Espagnol Mexicain (MX)",
      "fr-ca": "Français Canadien (CA)"
    }
  }]];
  _exports.default = _default;
});